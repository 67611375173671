import React from "react";
import { Spinner } from "react-bootstrap";
import './index.scss'

const Loading = () => {
    return <>
        <div className="custom-loading">
            <Spinner className="custom-loading__spinner" animation="border" variant="primary" />
        </div>        
    </>
}

export default Loading;