import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import SVG from "react-inlinesvg";
import { Button, makeStyles } from "@material-ui/core";

import CustomizedSnackbars from "../../../my-components/snackbars";
import { FormEditProject } from "./FormEditProject";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import api from "../../../services/api";
export const EditProjectModal = (props) => {
  const [show, setShow] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const handleClose = () => {
    setShow(false);
    setAlertConfig({
      isOpen: false,
      variant: "error",
      message: "",
    });
  };
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleShow = () => {
    setShow(true);
  };

  const handleSubmit = async (obj) => {
    try {
      await api.put(`/admin/project/update/${props.id}`, {
        ...obj,
      });
      handleClose();
      props.update(Math.random());
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Projeto modificado com sucesso",
      });
      setTimeout(()=>{
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
      },2000)
    } catch (error) {
      setAlertConfig({
        isOpen: true,
        variant: "error",
        message: "Erro ao editar projeto",
      });
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        variant="contained"
        size="small"
        color="primary"
        className={classes.margin}
      >
        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
      </Button>
      

      <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Editar Projeto <b>{props.name}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
         style={{ 
          
          maxHeight:"35rem",
          overflowY:"scroll"
        }}
        
        >
          <FormEditProject
            submit={handleSubmit}
            name={props.name}
            description={props.description}
            active={props.active}
            bilable={props.bilable}
            date={props.date}
            status={props.status}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button  onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
