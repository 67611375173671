import React from 'react';
import { makeStyles } from '@material-ui/core';
import {TextField} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function CreateDate(props) {
  const classes = useStyles();

  return (
    <div style={{display:'flex',justifyContent:'center'}} >
      <TextField
        fullWidth
        id="date"
        label="Data"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        value = {props.create_date}
        onChange = {(e)=>{props.setCreateDate(e.target.value)}}
      />
    </div>
  );
}