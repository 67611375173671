import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { TextField } from "@material-ui/core/";
import { Spinner } from "react-bootstrap";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: "100%",
  },
}));

export default function SelectRole(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const handleChange = (event) => {
    props.setRoleId(event.target.value);
  };

  useEffect(() => {
    api.get("/admin/role/list").then((res) => {
      const roles = res.data;
      const format = roles.map((role) => {
        return {
          value: role.id,
          label: role.title,
        };
      });
      setRoles(format);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
  }, []);

  if (loading) {
    return (
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="standard-select-currency"
          select
          label="Função"
          className={classes.textField}
          value={props.roleId}
          onChange={handleChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          helperText="Selecione a função"
          margin="normal"
        >
          {roles.map((role) => (
            <MenuItem key={role.value} value={role.value}>
              {role.label}
            </MenuItem>
          ))}
        </TextField>
      </form>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
}
