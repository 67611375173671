import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

function ModalUserNotFilled(props) {
  const [smShow, setSmShow] = useState(false);
  // const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <Button
        onClick={() => setSmShow(true)}
        className="me-2"
        variant="danger"
        size="sm"
      >
        !
      </Button>
      {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Horas não preenchidas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Dia</th>
                  <th>Horas</th>
                </tr>
              </thead>
              <tbody>

                {props.not_filled.length > 0 && props.not_filled.map((e, i) => {
                  const date = e.day.split("T")[0].split("-");
                  const formatDate = `${date[2]}/${date[1]}/${date[0]}`;
                  const minutesToHours = (min) => {
                    const hours = Math.floor(min / 60);
                    let minutes = (min % 60).toString();
                    if (minutes.length < 2) {
                      minutes = `0${minutes}`;
                    }
                    return `${hours}:${minutes}`;
                  };
                  return (
                    <tr key={i} >
                      <td>{i + 1}</td>
                      <td>{formatDate}</td>
                      <td>{minutesToHours(e.workTimeDay)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Large Modal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>...</Modal.Body>
        </Modal> */}
    </>
  );
}

export default ModalUserNotFilled;
