import React, { useState } from "react";
import { Button as ButtonStrap, Modal } from "react-bootstrap";
import { Button, makeStyles } from "@material-ui/core";

import api from "../../services/api";

import CustomizedSnackbars from "../../my-components/snackbars";
import TextFieldCreatAdmin from "./formCreateProject/TextFieldCreateAdmin";
import SelectRole from "./formCreateProject/SelectRole";

export const ModalCreateAdmin = (props) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [roleId, setRoleId] = useState("");
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });
  const handleClose = () => {
    setShow(false);
    setAlertConfig({
      isOpen: false,
      variant: "error",
      message: "",
    });
  };

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleShow = () => {
    setShow(true);
  };

  const handleSubmit = async (obj) => {
    try {
      await api.post(`/admin/manage-admin/create`, {
        name,
        email:email.trim(),
        password:password.trim(),
        roleId,
      });
      setName("");
      setPassword("");
      setEmail("");
      handleClose("");
      setName("");
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Admin criado",
      });
      setTimeout(() => {
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
      }, 1000);
      
      props.setAtt(Math.random());
    } catch (error) {
      setAlertConfig({
        isOpen: true,
        variant: "error",
        message: "Erro ao criar Admin",
      });
    }
  };

  return (
    <>
      {/* public */}

      <Button
        variant="contained"
        onClick={handleShow}
        color="secondary"
        className={classes.button}
      >
        Adicionar Admin
      </Button>

      <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextFieldCreatAdmin
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
          <SelectRole roleId={roleId} setRoleId={setRoleId} />
        </Modal.Body>
        <Modal.Footer>
          <ButtonStrap variant="primary" onClick={() => handleSubmit()}>
            Criar Admin
          </ButtonStrap>
          <ButtonStrap variant="secondary" onClick={handleClose}>
            Fechar
          </ButtonStrap>
        </Modal.Footer>
      </Modal>
    </>
  );
};
