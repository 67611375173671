import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";


import "./selectDate.css";
import { SelectProject } from "./SelectProject";
import { SelectSatus } from "./selectStatus";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 130,
  },
}));

export const SelectDate = (props) => {
  const classes = useStyles();
  const getDay = new Date().getDay();
  const initWeek =
    getDay === 0 || getDay === 6
      ? Date.now()  - (86400000 * 6)
      : Date.now() - getDay * 86400000;
  const initDate = new Date(initWeek).toISOString().split("T")[0];
  const finalDate = new Date().toISOString().split("T")[0];

  const [init, setInit] = useState(initDate);
  const [final, setFinal] = useState(finalDate);

  const changeInitDate = (e) =>{
    setInit(e.target.value)
  }
   const changeFinalDate = (e) =>{
    setFinal(e.target.value)
  }


  return (
    <div className="select-date-profile-timesheet">
      <form className={classes.container} noValidate>
        <TextField
          id="date"
          label="Início"
          type="date"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          value={init}
          onChange={changeInitDate}
        />
      </form>
      <form className={classes.container} noValidate>
        <TextField
          id="date"
          label="Final"
          type="date"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          value={final}
          onChange={changeFinalDate}
        />
      </form>
      <SelectProject  {...props} />
      <SelectSatus {...props} />
      <div>
      <button type="button" onClick = {(()=>{props.changedate(init,final)})} className="search-date-button"><SearchIcon fontSize="large"  color="primary"/></button>
      
      </div>
    </div>
  );
};
