import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSubheader } from "../../../_metronic/layout";
import Loading from "../../my-components/loading-spinner";
import api from "../../services/api";
import { ModalCreateAdmin } from "./ModalCreateAdmin";

import { ModalConfirmDeleteAdmin } from "./ModalConfirmDeleteAmdin";

export const ListAdmins = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Admin");

  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [att, setAtt] = useState(0);

  useEffect(() => {
    api.get("/admin/manage-admin/list").then((res) => {
      setAdmins(res.data);
    });
  }, [att]);

  useEffect(() => {
    setLoading(true);
  }, []);

  

  if (loading) {
    return (
      <>
        <div>
          <ModalCreateAdmin setAtt={setAtt} />
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Função</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => {
              return (
                <tr key={admin.id}>
                  <td style={{ verticalAlign: "middle" }}>{admin.name}</td>
                  <td style={{ verticalAlign: "middle" }}>{admin.email}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    {admin.role.title}
                  </td>
                  <td>
                 
                    <ModalConfirmDeleteAdmin  name={admin.name} adminId={admin.id}  att={setAtt} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  } else {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }
};
