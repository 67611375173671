import React from 'react';
import Switch from '@material-ui/core/Switch';

export default function FilterByNotFilled(props) {
  const handleChange = () => event => {
    props.filterNotFilled(event.target.checked)
  };

  return (
    <div>
      <Switch
        checked={props.check}
        onChange={handleChange(props.check)}
        value={props.check}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      Não preenchido
    </div>
  );
}