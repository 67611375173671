

import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import api from '../../../services/api';

class MyVerticallyCenteredModal extends React.Component {

    changeRole = async(block) =>{
       
        try {
            await api.patch('/admin/refresh-token/user/active',{
                email:this.props.email,
                active:block
            })
            window.location.reload()
        } catch (error) {
            alert('Erro ao alterar acesso do usuário')
        }
    }
    render() {
       
           return (
             <Modal
               {...this.props}
               size="sm"
               aria-labelledby="contained-modal-title-vcenter"
               centered
             >
               <Modal.Header closeButton>
                 <Modal.Title id="contained-modal-title-vcenter">
                   Limitar acesso ao sistema
                 </Modal.Title>
               </Modal.Header>
               <Modal.Body>
               <div style={{display:'flex',justifyContent:'space-between'}}>
               {this.props.isblock ?

                    <div><Button  variant="danger" onClick={()=>{this.changeRole(false)}}>Bloquear</Button></div>
                    :
                    <div><Button  variant="success" onClick={()=>{this.changeRole(true)}}>Desbloquear</Button></div>
                    
                }
                <Button variant='warning' onClick={this.props.onHide}>Fechar</Button>
                </div>
                 
                 
               </Modal.Body>
               <Modal.Footer>
                
               
               </Modal.Footer>
             </Modal>
           )
       }
  }
  
  export class BlockUserModal extends React.Component {
    constructor(...args) {
      super(...args);
  
      this.state = { modalShow: false };
    }
  
    render() {
      let modalClose = () => this.setState({ modalShow: false });
  
      return (
        <>
          {/* <Button
            variant="primary"
            onClick={() => this.setState({ modalShow: true })}
          >
            Launch vertically centered modal
          </Button> */}
          {
            this.props.block?
              (<span  onClick={() => this.setState({ modalShow: true })} className="label label-lg label-light-danger label-inline font-weight-bold py-4 btn-position">Bloquear</span>)
              :
              (<span  onClick={() => this.setState({ modalShow: true })} className="label label-lg label-light-warning label-inline font-weight-bold py-4 btn-position">Desbloquear</span>)
            
          }
          <MyVerticallyCenteredModal
            show={this.state.modalShow}
            onHide={modalClose}
            isblock = {this.props.block}
            email = {this.props.email}
          />
        </>
      );
    }
  }
  