import React from "react";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}));

export default function TextFieldOccupation(props) {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        fullWidth
        id="standard-name"
        label="Título"
        className={classes.textField}
        value={props.title}
        onChange={(e) => {
          if(e.target.value.length < 40){
            props.setTitle(e.target.value);
          }
          
        }}
        margin="normal"
      />
    </form>
  );
}
