import { useSubheader } from "../../../../_metronic/layout";
import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import Loading from "../../../my-components/loading-spinner/index.jsx";
import { Table } from "react-bootstrap";
import FilterByDate from "./FilterDate";
import SearchIcon from "@material-ui/icons/Search";
import ChartSatusProject from "./ChartStatusProject";
import { NavLink } from "react-router-dom";
import "./projectProfileStyle.css";
import ChartOccupatinProject from "./ChartOccupationProject";
import { AddProjectStatusModal } from "./AddProjectStatusModal";
import { ListStatusProject } from "./ListStatusProject";

export const ProjectInfoPage = (props) => {
  const suhbeader = useSubheader();
  const [timesheets, setTimesheets] = useState([]);
  const [project, setProject] = useState({ name: " " });
  const [isLoading, setLoading] = useState(true);
  const [sumMinutes, setSumMinutes] = useState("");
  const [listUserTime, setListUserTime] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const [finalDate, setFinalDate] = useState(" ");
  const [initDate, setInitDate] = useState(" ");
  const [att, setAtt] = useState(" ");

  const minutesToHours = (min) => {
    const hours = Math.floor(min / 60);
    let minutes = (min % 60).toString();
    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  suhbeader.setTitle(`Projeto ${project.name}`);

  useEffect(() => {
    api
      .get(`/admin/project/all/timesheets/${props.match.params.id}`)
      .then((res) => {
        const { timesheets } = res.data;
        setProject(res.data.project);
        setTimesheets(timesheets);
        setFilterDate(timesheets);
        setFinalDate(new Date().toISOString().split("T")[0]);
        setInitDate(res.data.project.create_date.split("T")[0]);
        const sumTotalMinutes = [...timesheets].reduce((acc, e) => {
          return acc + e.time;
        }, 0);
        setSumMinutes(sumTotalMinutes);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, [att]);

  useEffect(() => {
    if (timesheets) {
      const uniqIds = [];
      const uniqueUsersids = [];

      filterDate.forEach((element) => {
        if (!uniqIds.includes(element.user_id.id)) {
          uniqIds.push(element.user_id.id);
          uniqueUsersids.push({
            id: element.user_id.id,
            email: element.user_id.email,
            name: element.user_id.name,
            occupation: element.user_id.occupation.title,
          });
        }
      });

      const userSum = uniqueUsersids.map((usersUnique) => {
        const userTimesheets = filterDate.filter(
          (timesheet) => usersUnique.id === timesheet.user_id.id
        );
        const sumTime = userTimesheets.reduce((acc, sum) => {
          return acc + sum.time;
        }, 0);

        return {
          id: usersUnique.id,
          email: usersUnique.email,
          name: usersUnique.name,
          occupation: usersUnique.occupation,
          sum: sumTime,
        };
      });
      const sortUserSum = userSum.sort((a, b) => b.sum - a.sum);
      const totalSum = userSum.reduce((acc, e) => {
        return acc + e.sum;
      }, 0);
      setListUserTime(sortUserSum);
      setSumMinutes(totalSum);
    }
  }, [filterDate, timesheets]);

  const search = () => {
    const copyTimesheet = [...timesheets];

    const filterByDate = copyTimesheet.filter((e) => {
      const formDate = new Date(e.date.split("T")[0]);
      const init = new Date(initDate);
      const final = new Date(finalDate);

      return formDate >= init && formDate <= final;
    });
    setFilterDate(filterByDate);
  };
  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <h4 style={{ marginBottom: "2rem" }}>
            Tempo total do projeto: {minutesToHours(sumMinutes)}horas.
          </h4>
        </div>
        <div style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }} >
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-start",
            }}
          >
            <FilterByDate
              init={initDate}
              setInit={(date) => {
                setInitDate(date);
              }}
              final={finalDate}
              setFinal={(date) => {
                setFinalDate(date);
              }}
              createDate={project.create_date.split("T")[0]}
            />

            <button
              type="button"
              onClick={search}
              className="search-date-button"
            >
              <SearchIcon fontSize="large" color="primary" />
            </button>
            
          </div>
          <div style={{ width:"280px",display: "flex", justifyContent: "space-between" }}>
              <div>
                <AddProjectStatusModal project={project} setAtt={setAtt}  att={att} />
              </div>
              <div>
                <ListStatusProject project={project} setAtt={setAtt}/>
              </div>
            </div>
        </div>
        <div>
          <div className="team-project-info">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nome do usuário</th>
                  <th>Email</th>
                  <th>Ocupação</th>
                  <th>Tempo total (horas)</th>
                  <th style={{ textAlign: "center" }}>%</th>
                </tr>
              </thead>
              <tbody>
                {listUserTime.map((e) => {
                  const percentagem = ((e.sum / sumMinutes) * 100).toFixed(1);

                  return (
                    <tr key={e.id}>
                      <td>
                        <NavLink
                          to={`/usuario/perfil/${e.id}`}
                          className="user-name-link"
                        >
                          {e.name}
                        </NavLink>
                      </td>

                      <td>{e.email}</td>
                      <td>
                        {e.occupation === "none"
                          ? "Não selecionada"
                          : e.occupation}
                      </td>
                      <td>{minutesToHours(e.sum)}</td>
                      <td style={{ textAlign: "center" }}>{percentagem}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div>
            {filterDate.length > 0 ? (
              <div className="project-profile-charts">
                <ChartSatusProject timesheets={filterDate} />
                <ChartOccupatinProject timesheets={filterDate} />
              </div>
            ) : (
              <h4>Não foi cadastrada nenhuma timesheet nesse período!</h4>
            )}
          </div>
        </div>
      </>
    );
  }
};
