import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import "./chart.css";
// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

const COLORS = [
  "#0088FE",
  "#FFBB28",
  "#EC407A",
  "#00C49F",
  "#795548",
  "#FF8042",
  "#AB47BC",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export default function UserTimesheetsCharts(props) {
  const [timeProject, setTimeProject] = useState([]);
  const [timeStatus, setTimeStatus] = useState([]); 
  useEffect(() => {
    const uniqueProjects = [];
    props.timesheets.forEach((e) => {
      if (!uniqueProjects.includes(e.project_id.name)) {
        uniqueProjects.push(e.project_id.name);
      }
    });
    const projectTime = uniqueProjects.map((projectName) => {
      const time = {
        name: projectName,
        value: [...props.timesheets]
          .filter((e) => e.project_id.name === projectName)
          .reduce((acc, e) => {
            return acc + e.time;
          }, 0),
      };
      return time;
    });
    const uniqueStatus = [];
    props.timesheets.forEach((e) => {
      if (!uniqueStatus.includes(e.status_id.status)) {
        uniqueStatus.push(e.status_id.status);
      }
    });
    const statusTime = uniqueStatus.map((statusName) => {
      
      const time = {
        name: statusName,
        value: [...props.timesheets]
          .filter((e) => e.status_id.status === statusName)
          .reduce((acc, e) => {
            return acc + e.time;
          }, 0),
      };
      return time;
    });
    setTimeStatus(statusTime)
    setTimeProject(projectTime);
  }, [props.timesheets]);

  return (
    <>
      <div className="chart-user-container">
        <div className="chart-project-time">
          <h3>Tempo X Projeto</h3>
          <div>
            <PieChart width={200} height={200}>
              <Pie
                data={timeProject}
                cx={100}
                cy={100}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {timeProject.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
        <div className="chart-project-time">
          <h3>Tempo X Status</h3>
          <div>
            <PieChart width={200} height={200}>
              <Pie
                data={timeStatus}
                cx={100}
                cy={100}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {timeProject.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      </div>
    </>
  );
}
