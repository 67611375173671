import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Button as ButtonUi, makeStyles } from "@material-ui/core";
import api from "../../services/api";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { InputEditOccupation } from "./InputEditOccupation";
import CustomizedSnackbars from "../../my-components/snackbars";

const ModalEditOccupation = (props) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleChange = (e) => {
    const title = e.target.value;
    setTitle(title);
  };

  const handleSubmit = async (id) => {
    try {
      await api.patch(`/admin/occupation/update/occupation/${id}`, {
        newTitle: title,
      });
      handleClose();
     
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Ocupação modificada com sucesso",
      });
      setTimeout(()=>{
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
      },2000)
      props.att(Math.random());
    } catch (error) {
      setAlertConfig({
        isOpen: true,
        variant: "error",
        message: "Erro ao editar ocupação",
      });
      setTimeout(()=>{
        setAlertConfig({
          isOpen: false,
          variant: "error",
          message: "",
        });
      },2000)
      
    }
  };

  return (
    <>
      <ButtonUi
        onClick={handleShow}
        variant="contained"
        size="small"
        color="primary"
        className={classes.margin}
        disabled = {props.title === "none"? true:false}
      >
        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
      </ButtonUi>
      <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Ocupação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Deseja renomear a ocupação<b> {props.title} </b> ?
          </h5>
          <InputEditOccupation
            changeTitle={handleChange}
            title={title}
          ></InputEditOccupation>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => handleSubmit(props.id)}>
            Renomear
          </Button>
        </Modal.Footer>
        
      </Modal>
    </>
  );
};

export default ModalEditOccupation;
