import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { ListStatus } from './ListStatus';


export const ListStatusProject = (props) =>{
    const [show, setShow] = useState(false);
  
    const handleClose = () => {
      setShow(false);
    }
  
    const handleShow = () => {
      setShow(true);
    }
  
      return (
        <>
          <Button variant="primary" onClick={handleShow}>
            Atuações
          </Button>
  
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Atuações do projeto: {props.project.name} </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{height:"400px", overflowY:"scroll"}}>
                <ListStatus project={props.project} setAtt={props.setAtt}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button> */}
            </Modal.Footer>
          </Modal>
        </>
      );
  }
  
