import React from "react";

export function Dashboard() {
    
    return <>
    <div style={{backgroundImage:
    "url('/backgroundNotifications.png')"
    }}></div>
    </>;
}
