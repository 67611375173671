import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { ButtonDelete } from "./ButtonDelete";
import api from "../../services/api";
import CustomizedSnackbars from "../../my-components/snackbars";
export const ModalConfirmDeleteAdmin = (props) => {
  const [show, setShow] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const handleClose = () => {
    setShow(false);
    setAlertConfig({
        isOpen: false,
        variant: "error",
        message: "",
      });
  };

  const handleShow = () => {
    setShow(true);
  };

  const deleteAdmin = async () => {
    try {
      await api.delete(`/admin/manage-admin/delete/${props.adminId}`);
      
      setShow(false);
      window.location.reload();
    } catch (error) {
        setAlertConfig({
            isOpen: true,
            variant: "error",
            message: "Erro ao deletar Admin",
          });
    }
  };

  return (
    <>
      <ButtonDelete handleClick={handleShow} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Tem certeza que quer excluir o admin {props.name}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <Button
                variant="danger"
                onClick={() => {
                  deleteAdmin();
                }}
              >
                Confirmar
              </Button>
            </div>
            <div>
              <Button variant="secondary" onClick={handleClose}>
                Fechar
              </Button>
            </div>
          </div>
          <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
        </Modal.Body>
      </Modal>
    </>
  );
};
