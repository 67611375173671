import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CheckBoxField from "./CheckBoxField";
import CreateDate from "./CreateDate";
import SelectStatusIds from "./SelectStatusIds";
import TextClients from "./TextClient";

export const FormEditProject = (props) => {
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [create_date, setCreateDate] = useState(props.date.split("T")[0]);
  const [active, setActive] = useState(props.active);
  const [bilable, setBilable] = useState(props.bilable);
  const [statusIds, setSatusIds] = useState([...props.status.map((e) => e.id)]);

  return (
    <>
      <form
     
      >
        <TextClients
          name={name}
          description={description}
          setname={(name) => {
            setName(name);
          }}
          setdescription={(description) => {
            setDescription(description);
          }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CreateDate
            create_date={create_date}
            setCreateDate={(date) => {
              setCreateDate(date);
            }}
          />
          <CheckBoxField
            active={active}
            setactive={(active) => {
              setActive(active);
            }}
            bilable={bilable}
            setBilable={(active) => {
              setBilable(active);
            }}
          />
        </div>
        <SelectStatusIds
          initStatus={props.status}
          setstatus={(ids) => {
            setSatusIds(ids);
          }}
        />
      </form>
      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <Button
            style={{ paddingRight: "80px", paddingLeft: "80px" }}
            variant="primary"
            onClick={() => {
              props.submit({
                name,
                description,
                create_date,
                active,
                bilable,
                statusIds,
              });
            }}
          >
            Editar Projeto
          </Button>
        </div>
      </div>
    </>
  );
};
