import React from "react";
import {
  Dashboard
} from "../../_metronic/_partials";

export function DashboardPage() {

  
  return (
  <>
  <Dashboard />
    
  </>
  
  );
}
