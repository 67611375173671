import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import api from "../../services/api";
import { Table } from "react-bootstrap";
import ModalEditClient from "./ModalEditClients";
import { CreateProjectClient } from "./CreateProjectClient";
import ModalClientProjects from "./ModalClientProjects";

import { ButtonDelete } from "./ButtonDelete";
import { ModalCreateClient } from "./createClient/ModalCreateClient";

export const ListClients = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Clientes");

  const [clients, setClients] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [attClient, setAttClient] = useState(" ");

  useEffect(() => {
    api.get("/admin/client/all").then((res) => {
      const sorted = [...res.data].sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      );
      setClients(sorted);
    });
  }, [attClient]);

  useEffect(() => {
    setLoading(true);
  }, [clients]);

  const att = (number) => {
    setAttClient(number);
  };
  const deleteClient = async (id) => {
    try {
      await api.delete(`/admin/client/delete/${id}`);
      att(id);
    } catch (error) {
      alert("Erro no servidor!");
    }
  };
  return (
    <>
      {isLoading && (
        <div>
          <div>
            <ModalCreateClient att={att} />
          </div>
          <Table striped bordered hover responsive align="center">
            <thead>
              <tr>
                <th>Nome</th>
                <th style={{ textAlign: "center" }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client) => {
                return (
                  <tr key={client.id}>
                    <td style={{ verticalAlign: "middle" }}>{client.name}</td>

                    <td
                      style={{
                        verticalAlign: "middle",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <ModalClientProjects
                        projects={client.projects}
                        name={client.name}
                      />

                      <ModalEditClient
                        att={att}
                        clientid={client.id}
                        clientname={client.name}
                      />

                      <CreateProjectClient
                        id={client.id}
                        clientname={client.name}
                        loading={(isLoading) => {
                          setLoading(isLoading);
                        }}
                        att={att}
                      />
                      {/* <ModalDeleteClient
                      id={client.id}
                      clientname={client.name}
                       /> */}
                      <ButtonDelete
                        clientId={client.id}
                        delete={deleteClient}
                        existProject={client.projects.length > 0 ? true : false}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
