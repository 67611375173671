import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import api from "../../../services/api";
import CheckBoxField from "./CheckBoxField";
import CreateDate from "./CreateDate";
//import SelectStatusIds from "./SelectStatusIds";
import TextClients from "./TextClient";
export const FormCreateClientProject = (props) => {
  const client_id = props.clientid;
  const [name, setName] = useState(" ");
  const [description, setDescription] = useState(" ");
  const [create_date, setCreateDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [active, setActive] = useState(true);
  const [bilable, setBilable] = useState(false);
  // const [statusIds, setSatusIds] = useState([]);
  const [mainStatus, setMainStatus] = useState([]);

  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    api.get("/admin/status/all")
      .then((res) => {
        setMainStatus(res.data);
      })
      .then(()=>{
        setLoading(true)
      })
  },[])

  return (
    <>
      <form>
        <TextClients
          name={name}
          description={description}
          setname={(name) => {
            setName(name);
          }}
          setdescription={(description) => {
            setDescription(description);
          }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CreateDate
            create_date={create_date}
            setCreateDate={(date) => {
              setCreateDate(date);
            }}
          />
          <CheckBoxField
            active={active}
            setactive={(active) => {
              setActive(active);
            }}
            bilable={bilable}
            setBilable={(active) => {
              setBilable(active);
            }}
          />
        </div>
        {/* <SelectStatusIds
          setstatus={(ids) => {
            setSatusIds(ids);
          }}
        /> */}
      </form>
      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <Button
            style={{ paddingRight: "80px", paddingLeft: "80px" }}
            variant="primary"
            onClick={() => {
              props.submit({
                name,
                description,
                create_date,
                active,
                bilable,
                client_id,
                // statusIds,
              });
            }}
          >
            Criar Projeto
          </Button>
        </div>
      </div>
        {
          loading && mainStatus.length > 0?
          (<div style={{marginTop:'1rem'}}>
            <p>Status principais:</p>
          <ul>
            {mainStatus.map(e=>{
              return (<li>{e.status}</li>)
            })}
          </ul>
          </div>)
          :
          null
        }
    </>
  );
};
