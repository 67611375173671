import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomizedSnackbars from "../../../my-components/snackbars";
import api from "../../../services/api";
import { ListStatusAvailable } from "./ListStatusAvailable";
//import TextFieldCreateStatus from "./TextFieldCreateStatus";

export const AddProjectStatusModal = (props) => {
  const [show, setShow] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const handleClose = () => {
    setShow(false);
    setAlertConfig({
        isOpen: false,
        variant: "error",
        message: "",
      });
  };

  const handleShow = () => {
    setShow(true);
  };

  const submitStatus = async (id) => {

    try {
      const project = props.project;
      // const newStatus = await api.post(`/admin/status/create`, {
      //   status: statusName,
      // });
      await api.put(`/admin/project/update/${props.project.id}`, {
        name:project.name,
        description:project.description,
        create_date:project.create_date.split("T")[0],
        active:project.active,
        bilable:project.bilable,
        statusIds:[...project.status.map(e=>e.id), id],
      });
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Atuação adiconada com sucesso",
      });
      setTimeout(()=>{
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
        handleClose();
      },1000)
      props.setAtt(Math.random().toString());
    } catch {
        setAlertConfig({
            isOpen: true,
            variant: "error",
            message: "Erro ao adicionar atuação",
        });
        
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Adicionar Atuação
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Atuação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <TextFieldCreateStatus
            setStatus={setStatusName}
            status={statusName}
          /> */}

          <ListStatusAvailable close={handleClose} addStatus = {submitStatus} project = {props.project}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {/* <Button variant="primary" onClick={submitStatus}>
            Adicionar atuação
          </Button> */}
        </Modal.Footer>
        <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
      </Modal>
    </>
  );
};
