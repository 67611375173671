import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { ButtonDelete } from "../admins/ButtonDelete";
import api from "../../services/api";
import CustomizedSnackbars from "../../my-components/snackbars";
export const ModalDeleteStatus = (props) => {
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const handleClose = () => {
    setShow(false);
    setAlertConfig({
      isOpen: false,
      variant: "error",
      message: "",
    });
  };

  const handleShow = () => {
    setShow(true);
  };

  const deleteStatus = async () => {
    try {
      await api.delete(`/admin/status/delete/${props.statusid}`);

      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Atuação deletada com sucesso",
      });
      setTimeout(() => {
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
        handleClose();
      }, 1000);
      props.att(props.statusid);
      setShow(false);
    } catch (error) {
      console.log(error.response.data.msg);
      setAlertConfig({
        isOpen: true,
        variant: "error",
        message: "Erro ao deletar atuação",
      });
      setErrorMsg(error.response.data.msg);
    }
  };

  return (
    <>
      <ButtonDelete handleClick={handleShow} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Tem certeza que deseja excluir o status {props.statusname}?
          </Modal.Title>
        </Modal.Header>
        {errorMsg && <Modal.Body>
          <p style={{color:"red"}} >{errorMsg}</p>
        </Modal.Body>}
        <Modal.Footer style={{ textAlign: "center !important" }}>
          <div style={{width:"190px", display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button
                variant="danger"
                onClick={() => {
                  deleteStatus();
                }}
              >
                Confirmar
              </Button>
            </div>
            <div>
              <Button variant="secondary" onClick={handleClose}>
                Fechar
              </Button>
            </div>
            
          </div>
          <CustomizedSnackbars
            isOpen={alertConfig.isOpen}
            variant={alertConfig.variant}
            message={alertConfig.message}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
