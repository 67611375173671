
import React from "react";
import { makeStyles } from "@material-ui/core";
import {InputLabel} from '@material-ui/core';
import {FormControl} from '@material-ui/core';
import {Select} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "140px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  const  SelectProject = (props) => {
  const classes = useStyles();
  
  return (
   
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple">Projeto</InputLabel>
        <Select
          native
          value={props.project}
          onChange={(e)=>{props.setProject(e.target.value)}}
          inputProps={{
            name: 'Projeto',
            id: 'age-native-simple',
          }}
        >
          
          {
              props.allProjects.map((project,i)=>
                <option key={project + i} value={project}>{project}</option>
              )
          }
          
        </Select>
      </FormControl>

  );
}
