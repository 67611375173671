
import React from "react";
import { makeStyles } from "@material-ui/core";
import {InputLabel} from '@material-ui/core';
import {FormControl} from '@material-ui/core';
import {Select} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "140px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  const  SelectSatus = (props) => {
  const classes = useStyles();
  
  
  return (
   
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple">Status</InputLabel>
        <Select
          native
          value={props.filterStatus}
          onChange={(e)=>{props.setFilterStatus(e.target.value)}}
          inputProps={{
            name: 'Projeto',
            id: 'age-native-simple',
          }}
        >
          <option key={"Todos"} value={"Todos"}>Todos</option>
          {
              props.allStatus.map((status,i)=>
                <option key={status.id} value={status.status}>{status.status}</option>
              )
          }
          
        </Select>
      </FormControl>

  );
}
