import React, {useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import api from "../../services/api";
import { Table } from "react-bootstrap";
import { ModalCreateOccupation } from "./createOccupation/ModalCreateOccupation";
import ModalEditOccupation from "./ModalEditOccupation";

export const ListOccupations = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Ocupações");

  const [occupation, setOccupation] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [att, setAtt] = useState('');

  

  useEffect(() => {
    api.get("/admin/occupation/list").then((res) => {
      const revertArr = res.data.reverse()
     setOccupation(revertArr);
    });
  }, [att]);

  useEffect(()=>{
    setLoading(true)
  },[])


  return(
    <>
      {isLoading && (
        <div>
          <div>
            <ModalCreateOccupation att={setAtt}/>
          </div>
          
          <Table striped bordered hover responsive align="center">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Renomear</th>
              </tr>
            </thead>
            <tbody >
              {occupation.map((occupation) => {
                
                return (
                  <tr key={occupation.id}>
                    <td style={{verticalAlign:'middle'}}>{occupation.title}</td>
                    
                    <td >
                    <ModalEditOccupation id={occupation.id} title={occupation.title} att={setAtt}/>
                    </td>
                   
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};


