import React from "react";
import { Checkbox } from "@material-ui/core";

export default function CheckBoxField(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "1.5rem",
      }}
    >
      <div style={{ marginRight: "1.5rem" }}>
        <label>Ativo</label>
        <Checkbox
          checked={props.active}
          onChange={(e) => {
            props.setactive(e.target.checked);
          }}
          label="Ativo"
          color="primary"
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      </div>
      <div>
        <label>Rentável</label>
        <Checkbox
          checked={props.bilable}
          onChange={(e) => {
            props.setBilable(e.target.checked);
          }}
          color="primary"
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      </div>
    </div>
  );
}
