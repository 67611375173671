import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Button as ButtonUi, makeStyles } from "@material-ui/core";
import api from "../../services/api";
import { InputEditSatus } from "./InputEditStatus";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import CustomizedSnackbars from "../../my-components/snackbars";

const EditStatusModal = (props) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(" ");
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setShow(false);
    setAlertConfig({
      isOpen: false,
      variant: "error",
      message: "",
    });
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.value;
    setName(name);
  };

  const handleSubmit = async (id, att) => {
    try {
      await api.put(`/admin/status/edit/${id}`, {
        statusName: name,
      });
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Atuação renomeada com sucesso",
      });
      att(name);
      setTimeout(()=>{
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
        handleClose();
      },1000)
    } catch (error) {
      setAlertConfig({
        isOpen: true,
        variant: "error",
        message: "Erro ao renomear atuação",
      });
      
      
    }
  };

  return (
    <>
      <ButtonUi
        onClick={handleShow}
        variant="contained"
        size="small"
        color="primary"
        className={classes.margin}
      >
        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
      </ButtonUi>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Atuação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Deseja renomear a atuação<b> {props.statusname} </b> ?
          </h5>
          <InputEditSatus changeName={handleChange}></InputEditSatus>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSubmit(props.statusid, props.att)}
          >
            Renomear
          </Button>
          
        </Modal.Footer>
        <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
      </Modal>
    </>
  );
};

export default EditStatusModal;

