import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { Spinner } from "react-bootstrap";
import { loginApi } from "../../../services/login";
import CustomizedSnackbars from "../../../my-components/snackbars";

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const login = async () => {
    try {
      enableLoading();
      await loginApi(email, password);
      window.location.reload();
    } catch (error) {
      localStorage.clear();
      disableLoading();
      setAlertConfig(
        {
          isOpen: true,
          variant: "warning",
           message: "Email ou senha inválido",

        }
      )
    setTimeout(()=>{setAlertConfig(
      {
        isOpen: false,
        variant: "warning",
         message: "Email ou senha inválido",

      }
    )},2000)
      
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Admin Timesheet Login
          {loading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </h3>
        <p className="text-muted font-weight-bold">
          Entre com seu email e senha
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit={(e)=>{
        e.preventDefault()
        login()
      }}>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className="form-control form-control-solid h-auto py-5 px-6 "
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            name="email"
          />
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className="form-control form-control-solid h-auto py-5 px-6"
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button style={{ visibility: "hidden" }}></button>
          <button
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            type="button"
            onClick={login}
          >
            <span>Entrar</span>
          </button>
        </div>
      </form>
      {/*end::Form*/}
      <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
    </div>
  );
}


export default injectIntl(connect(null, auth.actions)(Login));
