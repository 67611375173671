import { Table } from "react-bootstrap";
import React, { useState } from "react";
import EditStatusModal from "../../status/EditModal";
import { ButtonDelete } from "../../clients/ButtonDelete";
import api from "../../../services/api";
import CustomizedSnackbars from "../../../my-components/snackbars";

export const ListStatus = (props) => {
    const [alertConfig, setAlertConfig] = useState({
        isOpen: false,
        variant: "success",
        message: "",
      });

  const removeStatus = async(statusid)=>{
    try {
        const filterStatus = props.project.status.map(status=>status.id).filter(e=>e !== statusid);
        const project = props.project;
        
      await api.put(`/admin/project/update/${props.project.id}`, {
        name:project.name,
        description:project.description,
        create_date:project.create_date.split("T")[0],
        active:project.active,
        bilable:project.bilable,
        statusIds:[...filterStatus],
      });
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Atuação removida com sucesso",
      });

      setTimeout(()=>{
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
        
      },1000)
      props.setAtt(statusid)
      
    } catch(e) {
        setAlertConfig({
            isOpen: true,
            variant: "error",
            message: "Erro ao remover atuação",
        });
        
    }

  }
  
  return (
      <>
    <Table striped hover>
      <tbody>
      {props.project.status.map(e=>
        <tr key={e.id}>
          <td style={{verticalAlign:"middle"}}>{e.status}</td>
          <td style={{display:"flex"}}>
              <EditStatusModal statusname={e.status} statusid={e.id} att={props.setAtt}/>
              <ButtonDelete clientId={e.id} delete={removeStatus}/>
          </td>
        </tr>
      )}
      </tbody>
      
    </Table>
    <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
    </>
  );
};
