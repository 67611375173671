import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_TIMESHEET_URL,
});

api.interceptors.request.use((config) => {
  
  const acessToken = getAcessToken();

  if (acessToken) {
    config.headers.Authorization = `Bearer ${acessToken}`;
  }
  return config;
});


api.interceptors.response.use(
  function (response) {
  return response;
}, async function (err) {
  const refreshToken = getRefreshToken()
  const originalConfig = err.config;
  const acessToken = getAcessToken()

  if(!acessToken && err.response.status===404){
    window.localStorage.clear()
    window.location.reload()
    return Promise.reject(err)
  } 
  if (err.response) {
    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const rs = await api.post('/refresh-token/admin',{
          refreshToken,
          acessToken : getAcessToken()
        });
        const { acessToken } = rs.data;
        window.localStorage.setItem("acessToken", acessToken);
        api.defaults.headers.common['Authorization'] = `Bearer ${acessToken}`;
        return api(originalConfig);
      } catch (_error) {
        if (_error.response && _error.response.data) {
          window.localStorage.clear()
          window.location.reload()
          return Promise.reject(_error.response.data);
        }
        window.localStorage.clear()
          window.location.reload()
        return Promise.reject(_error);
      }
    }
    if (err.response.status === 403 && err.response.data) {
      return Promise.reject(err.response.data);
    }
  }
  return Promise.reject(err);
});

function getAcessToken() {
  const acessToken = localStorage.getItem("acessToken");
  return acessToken;
}
function getRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
}

export default api;
