import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import api from "../../../services/api";
import RoleSelect from "./RoleSelect";
class MyVerticallyCenteredModal extends React.Component {
  state = {
    role: " ",
  };

  componentDidMount = () => {
    this.setState({
      role: this.state.role,
    });
  };

  changeRole = async () => {
    try {
      await api.patch(`/admin/occupation/update/user`, {
        userId: this.props.userid,
        occupationId: this.state.role,
      });

      this.props.setAtt(Math.random())
      this.props.onHide()
    } catch (error) {
      alert("Erro ao posição");
    }
  };
  setRole = (roleId) => {
    this.setState({
      role: roleId,
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar Ocupação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RoleSelect
            setrole={this.setRole}
            currentRole={this.props.occupation}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={this.changeRole}>
            Alterar
          </Button>
          <Button variant="warning" onClick={this.props.onHide}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const EditRole = (props) => {
  const [modalShow, setModalShow] = useState(false);

  const modalClose = () => setModalShow(false);

  return (
    <>
      <span
        onClick={() => setModalShow(true)}
        className="label label-lg label-light-primary label-inline btn-position"
      >
        Ocupação
      </span>

      <MyVerticallyCenteredModal
        occupation={props.occupation}
        show={modalShow}
        onHide={modalClose}
        userid={props.userid}
        setAtt={props.setAtt}
      />
    </>
  );
};

// export class EditRole extends React.Component {
//   constructor(...args) {
//     super(...args);

//     this.state = { modalShow: false };
//   }

//   render() {
//     let modalClose = () => this.setState({ modalShow: false });

//     return (
//       <>
//         {/* <Button
//             variant="primary"
//             onClick={() => this.setState({ modalShow: true })}
//           >
//             Launch vertically centered modal
//           </Button> */}

//         <span
//           onClick={() => this.setState({ modalShow: true })}
//           className="label label-lg label-light-primary label-inline btn-position"
//         >
//           Ocupação
//         </span>

//         <MyVerticallyCenteredModal
//           occupation = {this.props.occupation}
//           setatt = {(value)=>{this.props.setAtt(value)}}
//           show={this.state.modalShow}
//           onHide={modalClose}
//           userid={this.props.userid}
//         />
//       </>
//     );
//   }
// }
