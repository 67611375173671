import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Select } from "@material-ui/core";
import api from '../../../services/api'


const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function RoleSelect(props) {
  const classes = useStyles();
  const [position, setPosition] = React.useState(props.currentRole);
  const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = React.useState([]);

  function handleChange(event) {
    setPosition(event.target.value)
    props.setrole(event.target.value)
  }
  
  useEffect(() => {
    api.get('/admin/occupation/list').then((response)=>{
        setRoles(response.data)
    })
   
  },[]);
  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }
  if (roles) {
    return (
      <form autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-controlled-open-select">Ocupação</InputLabel>
          <Select
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={position}
            onChange={handleChange}
            inputProps={{
              name: "position",
              id: "demo-controlled-open-select",
            }}
          >
          {
              roles.map(e=>{

               return  <MenuItem  key={e.id} value={e.id}>{e.title}</MenuItem>
              })
          }
            
          </Select>
        </FormControl>
      </form>
    );
  } else {
    return (
        <h1>Carregando</h1>
    )
  }
}
