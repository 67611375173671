import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function FilterByDate(props) {
  const [init, setInit] = useState(props.createDate);
  const [final, setFinal] = useState((new Date().toISOString().split('T')[0]));
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="init-date"
        label="Início"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        value={init}
        onChange={(e) => {
            props.setInit(e.target.value)
            setInit(e.target.value)
        }}
      />
      <TextField
        id="final-date"
        label="Final"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        value={final}
        onChange={(e) => {
            props.setFinal(e.target.value)
            setFinal(e.target.value)
        }}
      />
    </form>
  );
}
