import React from "react";
import { TextField } from "@material-ui/core";

export default function TextClients(props) {
 

  return (
    <>
      <div>
        <TextField
          required
          id="standard-full-width"
          label="Nome do projeto"
          style={{ margin: 8 }}
          placeholder="Insira o nome do projeto"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.setname(e.target.value);
          }}
          value={props.name}
        />
      </div>
      <div>
        <TextField
          id="standard-full-width"
          label="Descrição"
          style={{ margin: 8 }}
          placeholder="Insira a descrição do projeto"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          // className={classes.textField}

          onChange={(e) => {
            props.setdescription(e.target.value);
          }}
          value={props.description}
        />
      </div>
    </>
  );
}
