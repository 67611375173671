import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { Input } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import api from "../../../services/api";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 600,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectStatusIds(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loaded, setLoagind] = useState(false);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  useEffect(() => {
    api
      .get("/admin/status/all")
      .then((res) => {
        setStatus(res.data);
      })
      .then(() => {
        setLoagind(true);
      })

      .catch((err) => {
        alert("Erro ao conectar com o servidor!");
      });
  }, []);

  function handleChange(event) {
    setSelectedStatus(event.target.value);
    const ids = [...event.target.value.map((e) => e.id)];
    props.setstatus(ids);
  }

  if (loaded) {
    return (
      <div
        className={classes.root}
        style={{ 
          display: "flex", 
          justifyContent: "center",
        }}
      >
        <FormControl className={classes.formControl} fullWidth required >
          <InputLabel required htmlFor="select-multiple-chip" style={{display:"none"}}>
            Status
          </InputLabel>
          <Select
          style={{display:"none"}}
            multiple
            value={[...selectedStatus]}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selectedStatus) => (
              <div className={classes.chips}>
                {selectedStatus.map((value) => {
                  return(
                  <Chip
                    key={value.id}
                    label={value.status}
                    className={classes.chip}
                  />
                )})}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {status.map((status) => (
              <MenuItem
                key={status.id}
                value={status}
                style={getStyles(
                  status.id,
                  [...selectedStatus.map((e) => e.id)],
                  theme
                )}
              >
                {status.status}
              </MenuItem>
            ))}
          </Select>
          <div style={{marginTop:'1rem'}}>Status atuais:</div>
          <div>
            {props.initStatus.map(e=>(
              <Chip
               key={e.id}
              label={e.status}
                    className={classes.chip}

               />
            )
            
            )}
          </div>
        </FormControl>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  }
}
