import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button as ButtonStrap, Modal } from "react-bootstrap";
import api from "../../services/api";
import { InputEditClient } from "./InputEditClient";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const ModalEditClient = (props) => {
  const [name, setName] = useState(" ");
  const [show, setShow] = useState(false);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.value;
    setName(name);
  };

  const handleSubmit = async (id, att) => {
    try {
      await api.put(`/admin/client/edit/${id}`, {
        name: name,
      });
      handleClose();
      att(Math.random());
    } catch (error) {
      alert("Erro ao alterar nome do cliente");
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        variant="contained"
        size="small"
        color="primary"
        className={classes.margin}
      >
        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Deseja renomear o cliente<b> {props.clientname} </b> ?
          </h5>
          <InputEditClient changeName={handleChange}></InputEditClient>
        </Modal.Body>
        <Modal.Footer>
          <ButtonStrap variant="secondary" onClick={handleClose}>
            Fechar
          </ButtonStrap>
          <ButtonStrap
            variant="primary"
            onClick={() => handleSubmit(props.clientid, props.att)}
          >
            Renomear
          </ButtonStrap>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalEditClient;
