import api from './api'
export async function loginApi(email,password){
   
    try {
        const payload ={ 
            email,
            password
        };
        const {data} = await api.post("/admin/login", payload);
        
        window.localStorage.setItem('acessToken',data.acessToken);
        window.localStorage.setItem('refreshToken',data.refreshToken);

        const adminProfile = await api.get('/admin/manage-admin/profile');
        const userData = JSON.stringify(adminProfile.data);
        window.localStorage.setItem('userData', userData);
    } catch (error) {
        throw error
    }

}
