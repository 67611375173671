import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import api from "../../services/api";
import { Table } from "react-bootstrap";

import EditStatusModal from "./EditModal";
import { ModalCreateStatus } from "./createStatus/ModalCreateStatus";
import { ModalDeleteStatus } from "./ModalDeleteStatus";
import { SwitchToggleStatusMain } from "./SwitchToggleStatusMain";

export const ListStatus = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Atuações");

  const [status, setStatus] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [att, setAtt] = useState("");

  useEffect(() => {
    api.get("/admin/status/all").then((res) => {
      const revertArr = res.data.sort((a,b)=>a.status.localeCompare(b.status));
      setStatus(revertArr);
    });
    
  }, [att]);

  const attTable = (name) => {
    setAtt(name);
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
      {isLoading && (
        <div>
          <div>
            <ModalCreateStatus att={setAtt} />
          </div>

          <Table striped bordered hover responsive align="center">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Principal</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {status.map((status) => {
                return (
                  <tr key={status.id}>
                    <td style={{ verticalAlign: "middle" }}>{status.status}</td>

                    <td style={{ verticalAlign: "middle"}}> 
                    
                     
                      <SwitchToggleStatusMain status={status} att={setAtt}/> 
                      
                    
                    </td>

                    <td style={{display:"flex", justifyContent:"space-around"}}>
                      <EditStatusModal
                        att={attTable}
                        statusid={status.id}
                        statusname={status.status}
                      />
                      <ModalDeleteStatus
                        att={attTable}
                        statusid={status.id}
                        statusname={status.status}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
