import React, { useState } from "react";
import { Button as ButtonStrap, Modal } from "react-bootstrap";
import { Button, makeStyles } from "@material-ui/core";
import api from "../../../services/api";
import CustomizedSnackbars from "../../../my-components/snackbars";
import TextFieldClient from "./TextFieldClient";


export const ModalCreateClient = (props) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });
  const handleClose = () => {
    setShow(false);
    setAlertConfig({
      isOpen: false,
      variant: "error",
      message: "",
    });
  };

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleShow = () => {
    
    setShow(true);
  };

  const handleSubmit = async (obj) => {
    try {
      await api.post(`/admin/client/create`, {
        name
      });
     
      handleClose();
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Cliente criado",
      });
      setTimeout(()=>{
        setAlertConfig({
          isOpen: false,
          variant: "success",
          message: "",
        });
      },1000)
      props.att(Math.random());
    } catch (error) {
      setAlertConfig({
        isOpen: true,
        variant: "error",
        message: "Erro ao criar cliente",
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleShow}
        color="secondary"
        className={classes.button}
      >
        Adicionar Cliente
      </Button>

      <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <TextFieldClient name={name} setName={setName} />
        </Modal.Body>
        <Modal.Footer>
          <ButtonStrap variant="primary" onClick={() => handleSubmit()}>
            Criar Cliente
          </ButtonStrap>
          <ButtonStrap variant="secondary" onClick={handleClose}>
            Fechar
          </ButtonStrap>
        </Modal.Footer>
      </Modal>
    </>
  );
};
