import React from 'react';
import { makeStyles } from '@material-ui/core';
import {Input} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    margin: theme.spacing(1),
  },
}));

export const InputEditClient =(props)=> {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Input
        defaultValue={props.name}
        className={classes.input}
        inputProps={{
          'aria-label': 'Description',
        }}
        placeholder ="Insira o novo nome"
        value = {props.name}
        onChange = {props.changeName}
      />
    </div>
  );
}