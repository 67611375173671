import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import api from '../../services/api';

const PurpleSwitch = withStyles({
  switchBase: {
    color: red[700],
    '&$checked': {
      color: red[700],
    },
    '&$checked + $track': {
      backgroundColor: red[700],
    },
  },
  checked: {},
  track: {},
})(Switch);

// statusId={status.id} att={setAtt}
export const SwitchToggleStatusMain = (props) => {

  const handleChange = async()=>{
    try {
        await api.patch(`/admin/status/toggle/main/${props.status.id}`);
        const nameAtt = props.status.main ? "false" : "true"
        props.att(props.status.id + nameAtt)
    } catch (error) {
        alert('Erro no servidor!')
    }
    
  };

  return (
    <FormGroup >
      <FormControlLabel
        control={
          <PurpleSwitch
            checked={props.status.main}
            onChange={handleChange}
            value="checkedA"
          />
        }
        label={props.status.main ? "Sim" : "Não"}
      />
      
    </FormGroup>
  );
}