import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useSubheader } from "../../../_metronic/layout";
import api from "../../services/api";
import FilterByName from "./FilterByName";
import { Table } from "react-bootstrap";
import { ToggleSwitch } from "./ToggleSwitch";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button, makeStyles } from "@material-ui/core";
import { EditProjectModal } from "./EditProject/EditProjectModal";
import { NavLink } from "react-router-dom";

export const ListProjects = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Projetos");

  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [number, setNumber] = useState(0);
  const [allProjects, setAllProjects] = useState([]);
  

  
  useEffect(() => {
    api.get("/admin/project/all").then((res) => {
      setAllProjects(res.data);
      setProjects(res.data)
    })  
    
  }, [number]);

  const handleFilter = (name) => {
    
    const all = [...allProjects];
    setProjects(
      all.filter((e) =>
        e.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      )
    );
  };

  useEffect(() => {
    setLoading(true);
  }, []);
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <>
      {isLoading && (
        <div>
          <div>
            <FilterByName handleFilter={handleFilter} />
          </div>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Nome do Projeto</th>
                <th>Cliente</th>
                <th>Ativo</th>
                <th>Rentável</th>
                <th>Criação</th>
                <th style={{ textAlign: "center" }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => {
                const date = project.create_date.split("T")[0].split("-");
                const formatDate = `${date[2]}/${date[1]}/${date[0]}`;

                return (
                  <tr key={project.id} className="user-row-content">
                    <td style={{ verticalAlign: "middle" }}>{project.name}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      {project.client.name}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ToggleSwitch
                        checked={project.active}
                        id={project.id}
                        name={project.name}
                        description={project.description}
                        active={project.active}
                        bilable={project.bilable}
                        date={project.create_date}
                        status={project.status}
                        toggle="active"
                        update={(number) => {
                          setNumber(number);
                        }}
                      />
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <ToggleSwitch
                        checked={project.bilable}
                        id={project.id}
                        name={project.name}
                        description={project.description}
                        active={project.active}
                        bilable={project.bilable}
                        date={project.create_date}
                        status={project.status}
                        toggle="bilable"
                        update={(number) => {
                          setNumber(number);
                        }}
                      />
                    </td>
                    <td style={{ verticalAlign: "middle" }}>{formatDate}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <EditProjectModal
                          update={(number) => {
                            setNumber(number);
                          }}
                          id={project.id}
                          name={project.name}
                          description={project.description}
                          active={project.active}
                          bilable={project.bilable}
                          date={project.create_date}
                          status={project.status}
                        />
                      </div>
                      <div>
                        <NavLink to={`/projeto/info/${project.id}`}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            className={classes.margin}
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "media/svg/icons/General/Search.svg"
                              )}
                            />
                          </Button>
                        </NavLink>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
