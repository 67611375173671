import React from "react";
import { withStyles, makeStyles } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { Button } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "3px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#d9534f",
    borderColor: "#d9534f",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#ee2d41",
      borderColor: "#ee2d41",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export const ButtonDelete = (props) => {
  const classes = useStyles();
 
  return (
    <div className="btn-client-delete">
      <BootstrapButton
      // props.delete(props.adminId)
        onClick={()=>{props.handleClick()}}
        variant="contained"
        color="primary"
        disableRipple
        className={classes.margin}
    
      >
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
      </BootstrapButton>
    </div>
  );
};
