import React from "react";
import { withStyles } from "@material-ui/core";
import { FormGroup } from "@material-ui/core/";
import { FormControlLabel } from "@material-ui/core/";
import { Switch } from "@material-ui/core";
import api from "../../services/api";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "red",
    },
  },
  checked: {},
  track: {},
})(Switch);

export function ToggleSwitch(props) {
  const handleChange = async (event) => {
    try {
      await api.put(`/admin/project/update/${props.id}`, {
        name: props.name,
        description: props.description,
        active: props.toggle === "active" ? event.target.checked : props.active,
        bilable:
          props.toggle === "bilable" ? event.target.checked : props.bilable,
        create_date: props.date.split("T")[0],
        statusIds: [...props.status.map((e) => e.id)],
      });
      props.update(Math.random());
    } catch (error) {}
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <PurpleSwitch
            checked={props.checked}
            onChange={handleChange}
            value={props.checked}
          />
        }
      />
    </FormGroup>
  );
}
