import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

const COLORS = [
  "#FFBB28",
  "#0088FE",
  "#EC407A",
  "#00C49F",
  "#795548",
  "#FF8042",
  "#AB47BC",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export default function ChartOccupatinProject(props) {
  const [timeOccupation, setTimeOccupation] = useState([]);

  useEffect(() => {
    const uniqueOccupation = [];
    props.timesheets.forEach((e) => {
      if (!uniqueOccupation.includes(e.user_id.occupation.title)) {
        uniqueOccupation.push(e.user_id.occupation.title);
      }
    });
    const occupation = uniqueOccupation
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .map((occupation) => {
        const time = {
          name: occupation,
          value: [...props.timesheets]
            .filter((e) => e.user_id.occupation.title === occupation)
            .reduce((acc, e) => {
              return acc + e.time;
            }, 0),
        };
        return time;
      });

    setTimeOccupation(occupation);
  }, [props.timesheets]);

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5rem" }}
      >
        <div>
          <h3 style={{ textAlign: "center" }}>Tempo X Ocupação</h3>
          <div>
            <PieChart width={200} height={200}>
              <Pie
                data={timeOccupation}
                cx={100}
                cy={100}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {timeOccupation.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      </div>
    </>
  );
}
