import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button as ButtonStrap, ButtonToolbar, Modal, Table } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";


const ModalClientProjects = (props) => {
  const [show, setShow] = useState(false);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  return (
    <>
      <Button
        onClick={handleShow}
        variant="contained"
        size="small"
        color="secondary"
        className={classes.margin}
      >
        <SVG src={toAbsoluteUrl("media/svg/icons/General/Search.svg")} />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Projetos do cliente <b>{props.name}</b>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {
              <Table striped hover>
                <tbody>
                  {props.projects.map((project) => {
                    return (
                      <tr key={project.id}>
                        <td style={{ verticalAlign: "middle" }} >{project.name}</td>
                        <td><NavLink to={`/projeto/info/${project.id}`} ><ButtonToolbar><ButtonStrap block size="sm" variant="warning">Ver mais</ButtonStrap></ButtonToolbar></NavLink></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonStrap variant="secondary" onClick={handleClose}>
            Fechar
          </ButtonStrap>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalClientProjects;
