import React from "react";
import { TextField } from "@material-ui/core";

export default function TextFieldCreatAdmin(props) {
  return (
    <>
      <div>
        <TextField
          required
          id="standard-full-width"
          label="Nome"
          style={{ margin: 8 }}
          placeholder="Insira o nome"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.setName(e.target.value);
          }}
          value={props.name}
        />
      </div>
      <div>
        <TextField
          required
          id="standard-full-width"
          label="Email"
          style={{ margin: 8 }}
          placeholder="Insira o email"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          // className={classes.textField}

          onChange={(e) => {
            props.setEmail(e.target.value);
          }}
          value={props.email}
        />
      </div>
      <div>
        <TextField
          required
          id="standard-full-width"
          label="Senha"
          style={{ margin: 8 }}
          placeholder="Senha de 6 ou mais dígitos"
          type="password"
          autoComplete="current-password"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          // className={classes.textField}

          onChange={(e) => {
            props.setPassword(e.target.value);
          }}
          value={props.password}
        />
      </div>
    </>
  );
}
