import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import api from "../../services/api";
import { FormCreateClientProject } from "./formCreateProject/FormCreateClientProject";
import CustomizedSnackbars from "../../my-components/snackbars";
export const CreateProjectClient = (props) => {
  const [show, setShow] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    variant: "success",
    message: "",
  });

  const handleClose = () => {
    setShow(false);
    setAlertConfig({
      isOpen: false,
      variant: "error",
      message: "",
    });
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleSubmit = async (obj) => {
    try {
      await api.post(`/admin/project/create`, {
        name:obj.name.trim(),
        description:obj.description,
        create_date:obj.create_date,
        active:obj.active,
        bilable:obj.bilable,
        client_id:obj.client_id,
        statusIds:obj.statusIds,
      });
      handleClose();
      setAlertConfig({
        isOpen: true,
        variant: "success",
        message: "Projeto Criado",
      });
      props.att(Math.random());
    } catch (error) {
      setAlertConfig({
        isOpen: true,
        variant: "error",
        message: "Erro ao criar projeto",
      });
    }
  };

  return (
    <>
      {/* public */}

      <button
        onClick={handleShow}
        className="bg-warning text-dark"
        style={{
          display: "block",
          outline: "none",
          border: "none",
          borderRadius: "3px",
          width: "64px",
          height: "28px",
          margin: "8px",
        }}
      >
        <SVG
          style={{ padding: "2px, 16px, 2px, 16px" }}
          src={"/media/svg/icons/Code/Plus.svg"}
        />
      </button>

      <CustomizedSnackbars
        isOpen={alertConfig.isOpen}
        variant={alertConfig.variant}
        message={alertConfig.message}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Criar Projeto para <b>{props.clientname}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
        style={{ 
          
          maxHeight:"35rem",
          overflowY:"scroll"
        }}
        
        >
          <FormCreateClientProject submit={handleSubmit} clientid={props.id} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
