import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {TextField} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}));



export default function FilterByName(props) {
  const classes = useStyles();
  const [name, setName] = useState('');

  const handleNameChange = e =>{
      e.preventDefault();
      setName(e.target.value)
     
    props.filterName(e.target.value)
      
  }

  return (
    <form className={classes.container} noValidate autoComplete="off">
     
      <TextField
        id="standard-with-placeholder"
        label="Busque pelo nome"
        placeholder="insira o nome"
        className={classes.textField}
        margin="normal"
        onChange={handleNameChange}
        value = {name}
      />
      
    </form>
  );
}