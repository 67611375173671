import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import api from "../../services/api";
import SVG from "react-inlinesvg";
import { FilterByMonthYear } from "./UserListsFilter/FilterByMonthYear";
import FilterByName from "./UserListsFilter/FilterByName";
import "./userProfile.css";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ModalUserNotFilled from "./ModalUserNotFilled";
import FilterByNotFilled from "./UserListsFilter/FilterByNotFilled";

export const UserListBoard = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listagem de de usuários");

  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUser] = useState([]);
  const [date, setDate] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [nameFilter, setNameFilter] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [checkNotFilled, setCheckNotFilled] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setLoading(true)
    api.get(`/admin/user/all/${date.month}/${date.year}`).then((res) => {
        setUsers(res.data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        }));
        // eslint-disable-next-line
        checkCurrentMonth();
      })
    

  // eslint-disable-next-line  
  }, [date]);

  useEffect(() => {
    setLoading(true)
    const copyUser = [...users];
    const orderUser = copyUser.sort(function(a, b) {
      return a.name.localeCompare(b.name);
    });

    setFilterUser([...orderUser]);

  }, [users]);

  const changeDate = (date) => {
    const splitData = date.split("-");
    const objectData = {
      month: splitData[1],
      year: splitData[0],
    };
    setDate(objectData);
    filterNotFilled(false);
  };

  const filterByName = (name) => {
    const filteredUserNames = users.filter((e) => {
      return e.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    });
    setNameFilter(name);
    setFilterUser(filteredUserNames);
  };

  function filterNotFilled (check){
  
    if(check){
      const usersFilter = filterUsers.filter(user=>user.days_not_full_filled.length > 0);
      setFilterUser(usersFilter);
      setCheckNotFilled(true);
    }else{
     
      filterByName(nameFilter);
      setCheckNotFilled(false);
    }

  }
  useEffect(() => {
    setLoading(false);
  }, [isLoading]);

  function checkCurrentMonth(){
      const now = new Date();
      const month = now.getMonth()+1
      const year = now.getFullYear();
     
      if(month <= Number(date.month) && year <= Number(date.year)){
        setShowButton(false)
      }else{
        setShowButton(true)
      }    
  }

  return (
    <>
      {setFilterUser && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <div>
              <FilterByName usersList={users} filterName={filterByName} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                width: "30vw",
                minWidth:"400px"
              }}
            >
              <div>

                {showButton ? <FilterByNotFilled filterNotFilled = {filterNotFilled} check = {checkNotFilled}/>: null}
              </div>
              <FilterByMonthYear selectDate={changeDate} />
            </div>
          </div>

          <Table striped bordered hover responsive align="center">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Horas Trabalhadas</th>
                <th>Horas Extras</th>
                <th>Último Registro</th>
                <th>Timesheets</th>
              </tr>
            </thead>
            {!isLoading?
            <tbody>
              {filterUsers.map((user) => {
                const date = user.last_register
                  ? user.last_register.split("T")[0].split("-")
                  : null;
                const formatDate = date
                  ? `${date[2]}/${date[1]}/${date[0]}`
                  : null;
                const minutesToHours = (min) => {
                  const hours = Math.floor(min / 60);
                  let minutes = (min % 60).toString();
                  if (minutes.length < 2) {
                    minutes = `0${minutes}`;
                  }
                  return `${hours}:${minutes}`;
                };

                return (
                  <tr key={user.id} className="user-row-content">
                    <td style={{ verticalAlign: "middle" }}>{user.name}</td>
                    <td style={{ verticalAlign: "middle" }}>{user.email}</td>
                    <td
                      className="user-row-content-number"
                      style={{ verticalAlign: "middle" }}
                    >
                      {minutesToHours(user.total_minutes)}
                    </td>
                    <td
                      className="user-row-content-number"
                      style={{ verticalAlign: "middle" }}
                    >
                      {minutesToHours(user.overtime_minutes)}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {formatDate ? formatDate : "sem registro"}
                      {user.days_not_full_filled.length > 0 ? (
                        <span style={{ paddingLeft: "1rem" }}>
                          {" "}
                          <ModalUserNotFilled
                            not_filled={user.days_not_full_filled}
                          />{" "}
                        </span>
                      ) : null}
                    </td>
                    <td
                      className="user-row-btn"
                      style={{ verticalAlign: "middle" }}
                    >
                      <NavLink to={`/usuario/perfil/${user.id}`}>
                        <div className="user-row-content-btn">
                          <SVG
                            src={toAbsoluteUrl(
                              "media/svg/icons/General/Search.svg"
                            )}
                          ></SVG>
                        </div>
                      </NavLink>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            :null
            }
          </Table>
          {isLoading && (<div style={{textAlign:"center", height:"2rem"}}> <Spinner animation="border" variant="primary" /> </div>)}
        </div>
      )}
    </>
  );
};
