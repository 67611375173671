import { useEffect, useState } from "react";
import React from "react";
import { Spinner, Table } from "react-bootstrap";
import api from "../../../services/api";
import { SelectDate } from "./SelectDate";
import "../userProfile.css";
export const ProfileUserTimesheets = (props) => {
  const [timesheets, setTimesheets] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalTime, setTotalTime] = useState(0);
  const [allProjects, setAllProjects] = useState(["Todos"]);
  const [filterProject, setFilterProject] = useState("Todos");
  
  const [allStatus, setAllStatus] = useState([]);
  const [filterStatus, setFilterStatus] = useState("Todos");

  const userId = props.userId;

  const minutesToHours = (min) => {
    const hours = Math.floor(min / 60);
    let minutes = (min % 60).toString();
    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    const getDay = new Date().getDay();
    const initWeek =
      getDay === 0 ? Date.now() - 86400000 * 6 : Date.now() - getDay * 86400000;
    const initDate = new Date(initWeek).toISOString().split("T")[0];
    const finalDate = new Date().toISOString().split("T")[0];
    api
      .get(`/admin/user/profile/${userId}/timesheets/${initDate}/${finalDate}`)
      .then((response) => {
        setTimesheets(response.data);
        const total = response.data.reduce((acc, e) => {
          return acc + e.time;
        }, 0);

        const uniqueProjects = ["Todos"];
        response.data.forEach((timesheet) => {
          if (!uniqueProjects.includes(timesheet.project_id.name)) {
            uniqueProjects.push(timesheet.project_id.name);
          }
        });

        setAllProjects(uniqueProjects);

        setTotalTime(total);
        props.settimesheets(response.data);
      })
      .then(()=>{
        api.get("/admin/status/all").then(res=>{
          setAllStatus(res.data);
        })
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        alert("Server erro");
      });
    // eslint-disable-next-line
  }, []);
  const changeDate = async (init, final) => {
    const { data } = await api.get(
      `/admin/user/profile/${userId}/timesheets/${init}/${final}`
    );
    const uniqueProjects = ["Todos"];
    data.forEach((timesheet) => {
      if (!uniqueProjects.includes(timesheet.project_id.name)) {
        uniqueProjects.push(timesheet.project_id.name);
      }
    });

    setAllProjects(uniqueProjects);

    let allTimesheets = [...data];
    if (filterProject !== "Todos" && data) {
      allTimesheets = allTimesheets.filter(
        (e) => e.project_id.name === filterProject
      );
      if(allTimesheets.length === 0){
        allTimesheets = [...data]
      }
    }
    if (filterStatus !== "Todos" && data){
      allTimesheets = allTimesheets.filter(
        (e) => e.status_id.status === filterStatus
      )
    }
    const total = allTimesheets.reduce((acc, e) => {
      return acc + e.time;
    }, 0);
    setTotalTime(total);
    setTimesheets(allTimesheets);
    props.settimesheets(allTimesheets);
  };

  if (!isLoading) {
    return (
      <>
        <div>
          <div>
            <div className="title-box-user-timesheets">
              <SelectDate
                changedate={changeDate}
                setProject={setFilterProject}
                project={filterProject}
                allProjects={allProjects}

                allStatus = {allStatus}
                filterStatus = {filterStatus}
                setFilterStatus = {setFilterStatus}
              />
            </div>
            <div style={{ textAlign: "right" }}>
              <span className="label label-lg label-light-primary label-inline">
                <span style={{ fontSize: "18px" }}>
                  {" "}
                  Total (horas): {minutesToHours(totalTime)}{" "}
                </span>
              </span>
            </div>
          </div>

          <div>
            <Table
              striped
              bordered
              hover
              responsive
              align="center"
              className="table-user-timesheet"
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Projeto</th>
                  <th>Tempo (h)</th>
                  <th>Status</th>
                </tr>
              </thead>
              {timesheets.length > 0 ? (
                <tbody>
                  {timesheets.map((timesheet) => {
                    const date = timesheet.date.split("T")[0].split("-");
                    const formatDate = `${date[2]}/${date[1]}/${date[0]}`;
                    return (
                      <tr key={timesheet.id} className="user-row-content">
                        <td>{formatDate}</td>
                        <td>{timesheet.project_id.name}</td>
                        <td>{minutesToHours(timesheet.time)}</td>
                        <td>{timesheet.status_id.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr className="user-row-content">
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                    <td> - </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }
};
