import React, { Suspense } from "react";
import { Redirect, Switch} from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { ListClients } from "./pages/clients/ListClients";
import { ListProjects } from "./pages/projects/ListProjects";


import { DashboardPage } from "./pages/DashboardPage";
import { UserListBoard } from "./pages/users/UserListBoard";
import { UserProfile } from "./pages/users/UserProfile";
import { ListStatus } from "./pages/status/ListStatus";
import { ProjectInfoPage } from "./pages/projects/projectInfo/ProjectInfoPage";
import { ListAdmins } from "./pages/admins/ListAdmins";
import { ListOccupations } from "./pages/occupation/ListOccupation";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const acessToken = window.localStorage.getItem("acessToken");
  const refreshToken = window.localStorage.getItem("refreshToken");
  const userData = window.localStorage.getItem("userData");
  if (acessToken && refreshToken && userData) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        {acessToken && refreshToken && userData ? (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/dashboard" />
            }
            {/*begin Rotas Clientes */}
            <ContentRoute path="/lista-clientes" component={ListClients} />

            {/*end Rotas Clientes */}

            {/*begin Rotas Projetos */}
          
            <ContentRoute path="/lista-projetos" component={ListProjects} />
            <ContentRoute
              path="/projeto/info/:id"
              component={ProjectInfoPage}
            />
            {/*end Rotas Projetos */}

            {/*begin Rotas Usuários */}
            <ContentRoute path="/lista-usuarios" component={UserListBoard} />
            <ContentRoute path="/usuario/perfil/:id" component={UserProfile} />
            <ContentRoute path="/lista-admins" component={ListAdmins} />
            {/*end Rotas Usuários */}
            {/* list-occupation */}

            {/* begin Rotas Occupation */}
            <ContentRoute path="/lista-ocupacoes" component={ListOccupations} />
            {/* end Rotas Occupation */}

            {/*begin Rotas Status */}
            <ContentRoute path="/lista-atuacoes" component={ListStatus} />
            {/*end Rotas Satus */}

            <ContentRoute path="/dashboard" component={DashboardPage} />
           

           
          </Switch>
        ) : (
          <Redirect to="/auth/login" />
        )}
      </Suspense>
    );
  } else {
    return (
      <Switch>
        <Redirect to="/auth/login" />
      </Switch>
    );
  }
}
