import { Spinner, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import SVG from "react-inlinesvg";

export const ListStatusAvailable = (props) => {
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const alreadyStatus = props.project.status.map((e) => e.status);
    api
      .get("/admin/status/all")
      .then((res) => {
        const revertArr = res.data
          .filter((e) => !alreadyStatus.includes(e.status))
          .sort((a, b) => a.status.localeCompare(b.status));
        setStatus(revertArr);
      })
      .then(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  } else {
    return (
      <div
        style={{
          maxHeight: "450px",
          overflowY: "scroll",
        }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Status</th>
              <th>Adicionar</th>
            </tr>
          </thead>
          <tbody>
            {status.map((e) => {
              return (
                <tr key={e.id}>
                  <td style={{ verticalAlign: "middle" }}>{e.status}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <button
                      onClick={() => {
                        props.addStatus(e.id);
                      }}
                      className="bg-warning text-dark"
                      style={{
                        display: "block",
                        outline: "none",
                        border: "none",
                        borderRadius: "3px",
                        width: "64px",
                        height: "28px",
                        margin: "8px",
                      }}
                    >
                      <SVG
                        style={{ padding: "2px, 16px, 2px, 16px" }}
                        src={"/media/svg/icons/Code/Plus.svg"}
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
};
